h3 {
  color: #fff;
}
.cardImage {
  height: 80px !important;
}
.skillItem {
  margin: auto !important;
}
.showcase-content img {
  width: 80px !important;
  height: inherit;
  margin: auto !important;
}

.card {
  height: 150px !important;
  margin: 0 0 15px 15px;
}
.myCard {
  /* margin-bottom: 20px; */
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px auto;
  /* background-color: #fff; */
  color: #555;
  overflow: hidden;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myCard:hover {
  border-color: #337ab7;
  background-color: #2a4776 !important;
}
h2,
h4 {
  color: black;
}
.skillsHr {
  /* margin: 0 !important; */
  margin: 1px !important;
  box-shadow: none !important;
  height: 2px !important;
}
.cardImage img.img-fluid {
  margin-top: 5px !important;
  margin: auto;
}

i {
  color: #fff;
}
#arrowDownc {
  margin: auto;
  text-align: center;
  margin-top: 7%;
}
.myCard.text-centercol-sm-4.col-md-3.col-lg-2:last-child,
.myCard.text-centercol-sm-4.col-md-3.col-lg-2:nth-last-child(2) {
  margin: auto;
}
.react-tabs__tab-panel--selected {
  height: 300px !important;
}
ul.react-tabs__tab-list {
  background-color: #2a4776;
  width: max-content;
  margin: auto;
  border-bottom: none !important;
  border-radius: 5px;
}
.react-tabs__tab--selected {
  border-radius: 5px !important;
}
ul.react-tabs__tab-list li {
  padding: 15px !important;
  font-size: 14pt;
}
@media only screen and (max-width: 1100px) {
  .react-tabs__tab-panel--selected {
    height: initial !important;
  }
  ul.react-tabs__tab-list {
    background: none;
    width: initial;
    margin: auto;
  }
  ul.react-tabs__tab-list li {
    font-size: 8pt;
  }
  .myCard.text-centercol-sm-4.col-md-3.col-lg-2:last-child,
  .myCard.text-centercol-sm-4.col-md-3.col-lg-2:nth-last-child(2) {
    margin: 0 0 15px 10px;
  }
}
