.website-name:hover {
  text-decoration: underline;
}
.carousel .slide img {
  width: inherit !important;
  /* height: 400px !important; */
  height: 400px;
}
img.qrCodeImage {
  height: 50px !important;
}
.slider-wrapper.axis-horizontal {
  height: 450px !important;
}
.carouselData .cardOfWebsite {
  height: 100% !important;
  background: transparent;
  border: 2px solid #fff;
}
.carouselItem {
  display: flex;
}
img.myImageInCarousel {
  float: left;
  flex: 1;
  min-height: 50px !important;
}
.carouselItem .carouselData {
  flex: 2;
}
.carouselItem table {
  width: 100%;
  height: 100%;
}
.card.cardOfWebsite {
  /* background: #2a4776; */
  /* background: #212529; */
  margin-left: 15px;
  width: 95% !important;
}
/* .carouselItem table td {
  border: 1px solid black;
} */
.carouselItem a {
  font-weight: bold;
}
.carouselItem ul {
  display: inline-block;
  text-align: left;
}
h3 {
  color: #fff;
}

/* a#githubFont {
  color: black !important;
} */
.carouselData #carouelRight {
  display: none;
}
.myBtn {
  /* background-color: #ff8700 !important; */
  background-color: #2a4776 !important;
  z-index: 3;
}
a.btn.myBtn {
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 0;
}
/* td,
h2,
i {
  color: #fff !important;
} */
p.carousel-status {
  color: #fff !important;
  font-weight: normal !important;
  text-shadow: none !important;
  padding-right: 25px !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff !important;
  padding-right: 15px;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid black !important;
}
/* button.control-arrow.control-prev,
button.control-arrow.control-next {
  height: 400px;
} */

/* .carousel.carousel-slider .control-arrow:hover {
  background: none !important;
} */
.card-body {
  padding: 0 !important;
}
.carousel .thumb {
  border: none !important;
}
li.thumb.selected {
  border: 3px solid #ccc !important;
}
.mdbAnimationButton {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
}
i.fab.fa-github.fa-2x:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 1200px) {
  .carousel .slide img {
    width: 300px !important;
    height: inherit !important;
  }
  .carouselData {
    position: absolute;
  }
  .technologiesLi {
    font-size: 7pt;
  }
  .carouselData #carouelRight {
    display: block;
    position: absolute;
    left: 20px;
  }

  .card.cardOfWebsite {
    display: none;
    width: 100% !important;
  }
  .card-body {
    width: 100%;
    padding: 0;
    /* background: #212529; */
    margin-left: 12%;
    margin-top: 0 !important;
    z-index: 1 !important;
  }
  .carousel .slide img {
    width: 200px !important;
    height: inherit !important;
  }
}

@media only screen and (max-width: 760px) {
  img.qrCodeImage {
    display: none !important;
    margin-bottom: 5px;
  }
  .carousel .slider-wrapper {
    height: 400px !important;
  }
  .carousel .slide img {
    width: 100px !important;
    height: inherit !important;
  }
  .card-body {
    padding-top: 15px !important;
    margin: auto;
    margin-left: 0;
    margin-top: 20px !important;
  }
  .card-body h2,
  .card-body h5 {
    font-size: small;
  }
  button.control-arrow.control-prev,
  button.control-arrow.control-next {
    height: max-content;
    top: 50% !important;
  }
  .carousel .slider-wrapper {
    height: max-content !important;
  }
}
