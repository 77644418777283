html {
  scroll-behavior: smooth;
  scroll-padding-bottom: 0;
}

h1,
h2,
h3,
p,
span,
a,
li {
  font-family: cursive;
}
.container-fluid {
  min-height: 110vh;
}
.container-fluid {
  /* background-color: #000000b0 !important; */
  /* background-color: #ff8700 !important; */
  /* color: black !important; */
  /* background-color: #2a4776 !important; */

  background: rgb(83, 91, 170);
  background: linear-gradient(
    270deg,
    rgba(53, 70, 49, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(83, 91, 170, 0.5) 100%
  );
  color: #fff !important;
}
/* .container {
  padding: 15px;
} */
/* h1,
h2,
p,
li,
span,
strong {
  color: #fff;
} */
.display-h1 {
  font-size: 50px !important;
  line-height: 1.2 !important;
}
.sectionHeadline {
  margin-top: 2%;
}
/* .container-fluid:nth-child(even) {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 33%,
    rgba(83, 91, 170, 1) 100%,
    rgba(53, 70, 49, 0.2528361686471463) 100%
  ); */

/* background-color: #2a4776 !important; */
/* color: #fff !important;
} */
.container-fluid:nth-child(even) h2 {
  color: #fff !important;
}
.container {
  padding-top: 50px;
}
.activeNav {
  font-weight: bold !important;
  font-size: larger;
}
img {
  border-radius: 0.25rem !important;
}
hr {
  height: 5px !important;
  border: 0 !important;
  /* margin: 0 !important; */
  margin: 15px !important;
  box-shadow: 0 10px 10px -10px #8c8b8b inset !important;
}
.arrowDown i {
  color: white;
  font-size: xx-large;
}
.arrowDown i:hover {
  font-size: 40pt;
  transition: all 0.5s linear;
}
@media only screen and (max-width: 600px) {
  h1,
  h2,
  h3,
  p,
  span,
  a,
  li {
    font-family: Arial, Helvetica, sans-serif;
  }
}
