.nav-item {
  color: #fff;
  margin: auto;
  /* margin-right: 20px; */
  padding: 0 15px 0 15px;
}
a,
i.fas.fa-phone,
i.far.fa-envelope {
  color: #fff !important;
}
a {
  text-decoration: none !important;
}
a:hover {
  color: rgba(255, 255, 255, 0.75) !important;
}
.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}
.navbar-nav {
  margin: auto;
}

.mobileHr {
  display: none;
}
.activeLink {
  font-weight: bold;
}
.bg-dark {
  position: fixed !important;
  z-index: 2;
}
/* .activea {
  font-weight: bold;
} */

@media only screen and (max-width: 990px) {
  a.col-sm-1.navbar-brand {
    width: 10%;
    margin: auto;
  }
  .navFonts {
    padding: 8px !important;
  }
  span.col-sm-2.navbar-brand {
    position: absolute;
    left: 10%;
    top: 0;
    font-size: large;
  }
  button.navbar-toggler.collapsed {
    left: 0;
    position: absolute;
  }
  button.navbar-toggler.collapsed {
    left: 0;
    top: 0;
  }
  .navbar-dark .navbar-brand:focus,
  .navbar-dark .navbar-brand:hover {
    color: #fff;
    left: 50px;
    position: absolute;
  }
  button.navbar-toggler {
    left: 0;
    position: absolute;
    top: 0;
  }
  .nav-item {
    margin: 10px auto;
    padding: 0px 0px 0px 0px;
  }
  /* a.col-sm-2.navbar-brand {
    top: 0;
    position: absolute;
    left: 50px !important;
  } */
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .mobileHr {
    display: block;
  }
  .navLogo {
    right: 50px !important;
    position: absolute !important;
    top: 0;
    width: 50px !important;
  }
  .navLogo img {
    width: 50px !important;
  }
}
