h3 {
  color: #fff;
}
#cardImage {
  width: 120px;
}
.cardOfTechnologies {
  background: white !important;
}
.card {
  height: 150px !important;
  margin: 0 0 15px 15px;
}
h2 {
  color: black;
}

.card {
  /* margin: 15px !important; */
  width: max-content !important;
}
h4 {
  left: 0;
}
.myCard p {
  margin-top: 10px;
}
.cardOfTechnologies {
  padding: 0 !important;
  margin: 0 !important;
}
@media only screen and (max-width: 760px) {
  .card-body.cardOfTechnologies {
    width: 50px;
    padding: 0;
    margin: 0;
  }
  .card {
    margin: 0 0 15px 15px;
  }
  .colOfImage {
    width: 120px !important;
    margin: 5px;
  }
  .card-body.cardOfTechnologies {
    width: 120px;
  }
}
