.landingDiv {
  min-height: 100vh;
}

.landingImg {
  width: 400px !important;
  height: inherit;
  float: right;
}
img.col-md-6.col-sm-12.landingImg {
  /* position: absolute; */
  top: 20%;
  /* right: 10%; */
  /* border-radius: 0.25rem !important; */
  /* border-radius: 50% !important; */
}
.col-sm-12.landingContent {
  position: absolute;
  top: 30%;
  left: 20%;
  margin: -25px 0 0 -25px;
}
.landingContent {
  position: relative;
  text-align: center;
}
#arrowDownA {
  margin: auto;
  text-align: center;
  margin-top: 15%;
}
.caracteristics {
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
}
.caracteristics .caracteristicsDivs {
  flex: 1;
}
.caracteristics .caracteristicsDivs p {
  font-size: large;
}
.bounce {
  -webkit-animation: bounce 5s infinite;
  animation: bounce 5s infinite;
}
.bounceButton {
  -webkit-animation: bounce 58s infinite;
  animation: bounce 8s infinite;
}
img.qrCodeImage {
  width: 50px !important;
  height: inherit;
}
@media only screen and (max-width: 1200px) {
  .col-sm-12.landingContent,
  img.col-md-6.col-sm-12.landingImg {
    position: relative;
    top: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .col-sm-12.landingContent {
    top: 20px;
  }
  img.col-md-6.col-sm-12.landingImg {
    height: inherit;
    /* width: 200px !important; */
    top: 20px;
  }
  #arrowDownA {
    margin-top: 15%;
  }
}
@media only screen and (max-width: 720px) {
  img.col-md-6.col-sm-12.landingImg {
    width: 200px !important;
    margin-right: 23%;
  }
  .landingImgDiv {
    margin-top: 0 !important;
  }
  .animated.fadeIn.text-center {
    margin-top: 20%;
    margin-left: 20%;
  }
  .caracteristics {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
  }
  .caracteristics .caracteristicsDivs {
    padding-bottom: 15px;
  }
  .caracteristics .caracteristicsDivs > p,
  .caracteristics .caracteristicsDivs > i {
    display: inline-block;
    margin: 0 15px 0 15px;
  }
}
