h1 {
  font-size: 50px;
  line-height: 1.2;
}
.showcase-content {
  text-align: left;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.showcase-content li {
  padding: 10px 0;
  font-size: 14pt;
}
.itemOfExperience {
  margin-bottom: 30px;
}

.cardOfMe {
  position: relative;
  top: 10%;
  /* color: #fff; */
  text-align: center;
  margin: auto;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
.experienceLinks {
  text-decoration: underline !important;
}
.experienceTechnologies {
  font-weight: bolder;
}
/* .cardOfMe h2,
li {
  color: black !important;
} */
#arrowDownB {
  margin: auto;
  text-align: center;
  margin-top: 5% !important;
}

@media only screen and (max-width: 760px) {
  .showcase-content {
    text-align: left;
    height: 100%;
    display: initial;
    align-items: initial;
    justify-content: center;
    margin-top: 0;
  }
  .showcase-content p {
    font-size: 14pt !important;
  }
  .showcase-content ul li strong {
    font-size: 12pt !important;
  }
  .showcase-content li {
    padding: 0 !important;
  }
  .showcase-content li span {
    margin-left: 0 !important;
  }
}
