h2,
h5,
li,
p,
.technologiesLi {
  color: #fff;
}
#arrowDownWebsites {
  margin-top: 0;
}
.thumbs-wrapper.axis-vertical {
  margin: 0 auto;
}
@media only screen and (max-width: 1200px) {
  .carousel .slider-wrapper {
    height: 700px !important;
  }
  .carouselData.text-center {
    background: #212529;
    width: 100%;
    padding-right: 20px;
    padding-right: 80px !important;
  }
  .carouselData .cardOfWebsite {
    border: none !important;
  }
}
@media only screen and (max-width: 760px) {
  .carousel .slider-wrapper {
    height: 400px !important;
    color: #fff;
  }

  /* .carouselData.text-center {
    background: #212529 !important;
  } */
  button.control-arrow.control-next,
  button.control-arrow.control-prev {
    top: 20% !important;
  }
  .carousel .slide .landingImg {
    margin-top: 20%;
  }
  .carouselData.text-center {
    background: #212529;
    width: 100%;
    padding: 0 !important;
  }
  .card.cardOfWebsite {
    background: none !important;
    border: none !important;
  }
  .carousel.carousel-slider .control-arrow {
    margin-right: -20px !important;
  }
}
